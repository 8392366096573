'use strict';

const body = $('body');
const wind = $(window);
const bodyHTML = $('body, html');
const breakpoint = 768;

/* ======================================
Input number
====================================== */
(() => {
  const input = $('input[type=number]');
  const key_str = [69, 107, 109, 187, 189];

  input.on('keydown', (e) => {
    if (key_str.includes(e.which)) {
      return false;
    } else {
      return true;
    }
  });
})();

/* ======================================
Header
====================================== */
let scrollPosi;

function bodyFix() {
  scrollPosi = $(window).scrollTop();

  body.addClass('is-fixed').css({ top: -scrollPosi });
}

function bodyFixReset() {
  body.removeClass('is-fixed').css({ top: '0' });

  bodyHTML.scrollTop(scrollPosi);
}

(() => {
  const btn = $('.js-menu');
  const content = $('.c-header__content');

  wind.on('load resize', function () {
    setTimeout(
      () =>
        wind.outerWidth() < 768 && content.css('transition', '.3s ease-in-out'),
      500,
    );

    wind.outerWidth() > 767 && content.removeAttr('style');
  });

  if (btn.length) {
    btn.on('click', function () {
      const header = $(this).parents('.c-header');
      const header_content = header.find('.c-header__content');

      $(this).toggleClass('is-active');
      header_content.toggleClass('is-active');
      header.toggleClass('is-menu-open');

      if (header_content.hasClass('is-active')) {
        bodyFix();
      } else {
        bodyFixReset();
      }
    });

    $(window).bind('pageshow', function (event) {
      if (event.originalEvent.persisted) {
        $('.c-header__content').addClass('is-hide');
        btn.removeClass('is-active');
        $('.c-header__content').removeClass('is-active');
        $('.c-header').removeClass('is-menu-open');
        bodyFixReset();

        setTimeout(() => {
          $('.c-header__content').removeClass('is-hide');
        }, 0);
      }
    });
  }

  if ($('.js-header-scroll').length) {
    wind.on('scroll', () => {
      wind.scrollTop() > 30
        ? $('.js-header-scroll').addClass('is-scrolling')
        : $('.js-header-scroll').removeClass('is-scrolling');
    });
  }
})();

/* ======================================
Match height title of list2
====================================== */
(() => {
  $(window).on('load resize', function () {
    if (wind.outerWidth() > 767) {
      $('.js-mheight-title').matchHeight();
      $('.js-mheight-text').matchHeight();
      $('.js-matchHeight').matchHeight();
    }
    $('.c-list3__ttl').matchHeight();
    $('.js-mat-h').matchHeight();
    $('.js-mat-h2').matchHeight();
    $('.js-mat-h3').matchHeight();
  });
})();

/* ======================================
Banner1
====================================== */
(() => {
  const banner = $('.c-banner1');
  const btn = $('.js-close-banner1');
  const vh = wind.innerHeight();

  btn.length &&
    btn.on('click', () => {
      banner.addClass('is-hidden');

      wind.outerWidth() < 768 && $('.c-footer').css('padding-bottom', 0);
    });

  $(window).on('scroll load', function () {
    if (banner.length) {
      if ($(window).scrollTop() + vh >= $('.c-footer').offset().top) {
        banner.addClass('is-absolute');
      } else {
        banner.removeClass('is-absolute');
      }
    }
  });
})();

/* ======================================
MV
====================================== */
(() => {
  const swiper = new Swiper('.js-mv-slide', {
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    effect: 'fade',
    loop: true,
  });
})();

/* ======================================
list3 slide only mobile
====================================== */
(() => {
  let init = false;
  let jsList3SLider;
  if ($('.js-list3-slider').length > 0) {
    const slideList3 = () => {
      if (wind.outerWidth() < breakpoint) {
        if (!init) {
          init = true;
          jsList3SLider = new Swiper('.js-list3-slider', {
            loop: true,
            slidesPerView: 1.41,
            centeredSlides: true,
            spaceBetween: 37.5,
            speed: 1000,
            autoplay: {
              delay: 3000,
            },
          });
        }
      } else if (init) {
        jsList3SLider.destroy();
        init = false;
      }
    };

    slideList3();

    $(window).on('load resize', function () {
      slideList3();
    });
  }
})();

/* ======================================
list4 slide only mobile
====================================== */
(() => {
  let init = false;
  let jsList4SLider;
  if ($('.js-list4-slider').length > 0) {
    const slideList4 = () => {
      if (wind.outerWidth() < breakpoint) {
        if (!init) {
          init = true;
          jsList4SLider = new Swiper('.js-list4-slider', {
            loop: true,
            slidesPerView: 1.41,
            centeredSlides: true,
            spaceBetween: 36,
            speed: 1000,
            autoplay: {
              delay: 3000,
            },
          });
        }
      } else if (init) {
        jsList4SLider.destroy();
        init = false;
      }
    };

    slideList4();

    $(window).on('load resize', function () {
      slideList4();
    });
  }
})();

/* ======================================
faq
====================================== */
$(function () {
  $('.js-faq').each(function () {
    $('.js-faq-heading', this).on('click', function () {
      $(this).toggleClass('is-open');
      $(this).next('.js-faq-info').slideToggle();
    });
  });
});

/* ======================================
list11 slide only mobile
====================================== */
(() => {
  let jsList11SLider;
  if ($('.js-list11-slider').length > 0) {
    jsList11SLider = new Swiper('.js-list11-slider', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
      centeredSlides: true,
      speed: 1000,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }
})();

/* ======================================
responsive list12
====================================== */
(() => {
  $(window).on('load resize', function () {
    $('.js-height').each(function () {
      let widthItem = $(this).outerWidth();
      $(this).css('height', widthItem);
    });
  });
})();
